*,
*:before,
*:after {
	box-sizing: border-box;
}

:root {
	--app-height: 100vh;
}

body {
	position: relative;
	font-family: 'Roboto Mono', monospace;
	color: var(--color-maintext);
	background: var(--color-bg);
	-webkit-font-smoothing: antialiased;
}

html,
body {
	height: 100%;
}

.icon {
	width: 1em;
	height: 1em;
	fill: currentColor;
	vertical-align: middle;
}

button {
	-webkit-appearance: none;

	&:focus,
	&:active {
		outline: 0;
	}
}

.right-align {
	text-align: right;
}
