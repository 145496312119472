.c-tabs {
	display: flex;
	height: 38px;
	align-items: center;
	padding: 0 20px;
	border-bottom: 1px solid #fff;
}

.c-tabs__tab {
	font-size: 12px;
	font-weight: bold;
	line-height: normal;
	color: #ffffff;
	background: none;
	border: none;
	padding: 0;
	opacity: 0.5;
	transition: opacity 0.3s;

	&.is-active {
		opacity: 1;
	}

	+ .c-tabs__tab {
		margin-left: 25px;
	}
}
