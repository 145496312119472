.t-title {
	font-size: 24px;
	font-weight: bold;
	line-height: normal;
	margin: 0 0 12px 0;
}

.t-text {
	font-size: 14px;
	line-height: 1.2;
	margin: 0 0 24px 0;

	p {
		margin: 0;
	}
}

.t-textlink {
	display: block;
	text-align: center;
	color: var(--color-maintext);
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 1px;
}
