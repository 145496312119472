.c-modalwrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.3);
}

.c-modal {
    background: #000;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid white;
}

.c-modal__content {
    h2 {
        margin-top: 12px;
    }

    form {
        display: inline-block;
        margin: 0;

        button {
            margin-bottom: 12px;
        }

    }

    .cancel {
        background: transparent;
        border: 0;
        color: white;
        margin-left: 12px;
    }
}

.c-modal-close {
    color: #fff;
    align-self: flex-end;
    background: transparent;
    border: 0;
}