.c-menu {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #000;
	color: #fff;
	padding: 20px;
	padding-top: $c-app-header-height + 20;
	z-index: 100;
}

.c-menu__nav {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
}

.c-menu__nav-item {
	color: #fff;
	display: inline-block;
	font-size: 16px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	text-decoration: none;
	background-color: transparent;
	border: none;
	padding: 0;
	margin: 0;

	+ .c-menu__nav-item {
		margin-top: 20px;
	}
}
