.c-planet-radios {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 10px;
	row-gap: 10px;
	margin-bottom: 24px;
}

.c-planet-radio {
	position: relative;
	padding-bottom: 100%;

	label {
		height: 100%;
		width: 100%;
		background-color: #000;
		border: 1px solid #fff;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
	}

	input {
		height: 100%;
		width: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	input:checked + label {
		border-width: 4px;
	}
}

.c-planets-radio__shape {
	width: 52px;
	height: 52px;
	border-radius: 50%;
}
