//
// Global nieuwsmaker
//

.c-app-container--NieuwsmakerSlide {
	.c-app-header {
		@media (min-width: 768px) {
			display: none;
		}
	}
}

.v-nieuwsmaker {
	height: 100%;
	height: 100vh;

	.c-button.c-button--main {
		background-image: linear-gradient(114deg, #134d40 -33%, #3acbb0 127%);
	}

	@media (min-width: 768px) {
		background-image: radial-gradient(circle at 50% 64%, #090406, #090406 32%, #1c6a59 75%, #3acbb0 132%);
	}
}

.c-nieuwsmaker-logo {
	width: 176px;
	margin-bottom: 68px;
}

.c-nieuwsmaker-logo.c-nieuwsmaker-logo--intro {
	position: absolute;
	top: 40px;
	left: 72px;
}

.c-nieuwsmaker-logo__img {
	width: 100%;
	height: auto;
}

.c-play-button {
	margin: 0;
	padding: 0;
	border: none;
	overflow: visible;
	text-align: inherit;
	-webkit-appearance: none;
	cursor: pointer;
	width: 58px;
	height: 58px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 16px;
	}

	path {
		fill: #000;
	}
}

.c-play-button.c-play-button--nav {
	margin-right: 32px;
}

// Frame layout

.c-nieuwsmaker-frame {
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 288px;
}

.c-nieuwsmaker-frame__main {
	display: grid;
	grid-template-rows: 1fr 170px;
}

.c-nieuwsmaker-frame__content {
	padding: 40px 72px;
	display: flex;
	flex-flow: column;
}

.c-nieuwsmaker-frame__monitor {
	flex: 1;
}

.c-nieuwsmaker-frame__bar {
	background-color: #000;
	display: flex;
	align-items: center;
}

.c-nieuwsmaker-frame__sidebar {
	background-image: radial-gradient(circle at 50% 64%, #090406, #090406 32%, #1c6a59 75%, #3acbb0 132%);
}

// Video selector navigation

.c-selector-nav {
	padding: 0 0 0 72px;
	display: flex;
	align-items: center;
	height: 100%;
}

.c-selector-nav__steps {
	display: flex;
	align-items: flex-end;
	height: 100%;
	padding-bottom: 14px;
}

.c-selector-nav.c-selector-nav--editor {
}

// Selector step

.c-video-selector-step {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	width: auto;
	overflow: visible;
	text-align: inherit;
	-webkit-appearance: none;
	cursor: pointer;

	&.is-active {
		.c-video-selector-step__frame {
			width: 176px;
			height: 116px;
			background-color: rgba(255, 255, 255, 0.5);
		}

		.c-video-selector-step__label {
			opacity: 1;
		}
	}

	&.is-complete {
		.c-video-selector-step__frame {
			border: none;
		}
	}

	&.is-complete:not(.is-active) {
		.c-video-selector-step__frame {
			background-color: rgba(58, 203, 176, 0.2);
			color: #fff;
		}
	}

	+ * {
		margin-left: 12px;
	}
}

.c-video-selector-step__frame {
	border-radius: 2px;
	border: dashed 1px #ffffff;
	background-color: rgba(255, 255, 255, 0.2);
	width: 160px;
	height: 106px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	word-break: break-word;
}

.c-video-selector-step__music-selected {
	width: 53px;
}

.c-video-selector-step__thumb {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
}

.c-video-selector-step__label,
.c-video-editor-step__label {
	opacity: 0.5;
	font-size: 12px;
	font-weight: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	margin-top: 4px;

	// .is-active.c-video-selector-step & {
	// 	opacity: 1;
	// }
}

.c-video-selector-step.c-video-selector-step--next-view {
	.c-video-selector-step__frame {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: 500;
		color: #fff;
		text-transform: uppercase;
		border-style: solid;
		text-align: center;
	}
}

// Video editor step

.c-video-editor-step {
	width: 160px;
	position: relative;

	&.is-active {
		.c-video-editor-step__frame {
			height: 116px;
		}

		.c-video-editor-step__label {
			opacity: 1;
		}
	}

	+ * {
		margin-left: 12px;
	}
}

.c-video-editor-step__frame {
	height: 106px;
	display: flex;
	flex-flow: column;
}

.c-video-editor-step__label {
}

.c-video-editor-step__video,
.c-video-editor-step__recording {
	margin: 0;
	padding: 0;
	overflow: visible;
	text-align: inherit;
	-webkit-appearance: none;
	cursor: pointer;
	width: 100%;
	border-radius: 2px;
	border: dashed 1px #ffffff;
	background-color: rgba(255, 255, 255, 0.2);
	position: relative;

	&.is-active {
		background-color: rgba(255, 255, 255, 0.5);
	}

	&.is-complete {
		border: none;
	}
}

.c-video-editor-step__video {
	flex: 1;
}

.c-video-editor-step__recording {
	height: 20px;
	margin-top: 8px;

	&.is-complete {
		background: rgba(58, 203, 176, 0.2);
	}
}

.c-video-editor-step__thumb {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
}

// Sidebar

.c-sidebar {
	display: flex;
	flex-flow: column;
	height: 100%;
	max-height: 100vh;
}

.c-sidebar__header {
	padding: 40px 32px 0 32px;
}

.c-sidebar__title {
	font-size: 24px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	margin: 0 0 12px 0;
}

.c-sidebar__description {
	font-size: 14px;
	line-height: 1.2;
	color: #ffffff;
}

.c-sidebar__items {
	margin: 24px 0 24px 32px;
	padding: 24px 32px 0 0;
	flex: 1;
	position: relative;
	overflow-y: auto;

	&:after {
		content: '';
		height: 1px;
		background-color: #fff;
		top: 0;
		left: 0;
		right: 32px;
		position: absolute;
	}
}

// Sidebar video

.c-sidebar-video {
	width: 100%;
	height: 148px;
	position: relative;
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	overflow: visible;
	text-align: inherit;
	-webkit-appearance: none;
	cursor: pointer;

	&.is-active {
		border: 2px solid #fff;
		border-radius: 2px;
	}

	+ .c-sidebar-video {
		margin-top: 12px;
	}
}

.c-sidebar-video__thumb {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
}

// Selector monitor

.c-selector-monitor {
	height: 100%;
	display: flex;
	flex-flow: column;
	align-items: flex-end;
}

.c-selector-monitor__wrapper {
	border-radius: 10px;
	border: solid 2px #ffffff;
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	// height: 100%;
	flex: 1;
	position: relative;
	overflow: hidden;
}

.c-selector-monitor__duration {
	border: solid 2px #ffffff;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.8);
	height: 26px;
	font-size: 12px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	color: #ffffff;
	padding: 0 15px;
	margin-top: 12px;
	display: flex;
	align-items: center;
}

.c-selector-monitor__video {
	object-fit: contain;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	// &::-webkit-media-controls-volume-slider {
	// 	display: none;
	// }

	// &::-webkit-media-controls-mute-button {
	// 	display: none;
	// }
}

// Title editor

.c-title-editor {
	height: 100%;
	display: flex;
	flex-flow: column;
	align-items: flex-end;
}

.c-title-editor__wrapper {
	border-radius: 10px;
	background-color: #fff;
	width: 100%;
	flex: 1;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;

	font-size: 36px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
}

.c-title-editor__textarea {
	text-align: center;
	resize: none;
	height: 242px;
	width: 424px;
}

// Music preview

.c-music-preview {
	height: 100%;
	display: flex;
	flex-flow: column;
	align-items: flex-end;
}

.c-music-preview__wrapper {
	border-radius: 10px;
	border: solid 2px #ffffff;
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	flex: 1;
	position: relative;
	overflow: hidden;

	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

// Recorder

.c-recorder {
	height: 100%;
	display: flex;
	flex-flow: column;
	align-items: flex-end;
}

.c-recorder__wrapper {
	border-radius: 10px;
	border: solid 2px #ffffff;
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	flex: 1;
	position: relative;
	overflow: hidden;
}

.c-recorder__step {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.c-recorder__buttons {
	display: flex;

	+ .c-recorder__text,
	+ .c-recorder__help-text {
		margin-top: 30px;
	}
}

.c-recorder__text,
.c-recorder__help-text {
	font-size: 21px;
	font-weight: normal;
	line-height: 1.24;
	text-align: center;
	color: #ffffff;
	max-width: 260px;

	+ .c-recorder__help-text {
		margin-top: 15px;
	}
}

.c-recorder__help-text {
	font-size: 12px;
}

.c-recording-timer {
	font-size: 21px;
	font-weight: 500;
}

.c-record-button,
.c-stop-button,
.c-sidebar-track__play-button {
	margin: 0;
	padding: 0;
	overflow: visible;
	text-align: inherit;
	-webkit-appearance: none;
	cursor: pointer;
	border: none;
	width: 58px;
	height: 58px;
	border-radius: 50%;
	background-image: linear-gradient(136deg, #134d40 -36%, #3acbb0 126%);
	display: inline-flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 16px;
	}

	path {
		fill: #fff;
	}
}

.c-stop-button {
	span {
		width: 16px;
		height: 16px;
		background-color: #fff;
		border-radius: 2px;
	}
}

.c-stop-button.c-stop-button--small {
	width: 41px;
	height: 41px;

	span {
		width: 12px;
		height: 12px;
	}
}

.c-recording-button {
	padding: 9px 37px 8px 9px;
	border-radius: 39px;
	background-color: rgba(255, 255, 255, 0.25);
	display: flex;
	align-items: center;

	.c-stop-button {
		margin-right: 18px;
	}
}

.c-retry-button {
	margin: 0;
	padding: 0 24px;
	height: 58px;
	border-radius: 39px;
	overflow: visible;
	text-align: inherit;
	-webkit-appearance: none;
	cursor: pointer;
	border: 1px solid #fff;
	font-size: 21px;
	font-weight: 500;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	background: transparent;
}

.c-recorder__audio {
	margin-left: 12px;
}

// Music sidebar

.c-sidebar-track {
	margin: 0;
	overflow: visible;
	text-align: inherit;
	-webkit-appearance: none;
	cursor: pointer;
	border: none;
	padding: 0 9px;
	border-radius: 39px;
	background-color: rgba(255, 255, 255, 0.25);
	display: flex;
	align-items: center;
	width: 100%;
	height: 58px;

	&.is-active {
		border: 1px solid #fff;
	}

	+ .c-sidebar-track {
		margin-top: 12px;
	}
}

.c-sidebar-track__play-button {
	width: 41px;
	height: 41px;

	svg {
		width: 14px;
	}
}

.c-sidebar-track__label {
	font-size: 21px;
	font-weight: 500;
	line-height: normal;
	color: #ffffff;
	margin-left: 12px;
}

// Modal

.c-nieuwsmaker-modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #000;
	z-index: 500;
}

.c-selector-preview,
.c-editor-preview {
	position: relative;
	height: 100%;
	width: 100%;
}

.c-selector-preview__close,
.c-editor-preview__close {
	position: absolute;
	right: 20px;
	top: 20px;
}

.c-selector-preview__frame,
.c-editor-preview__frame {
	width: 100%;
	height: 100%;
	position: relative;
}

.c-selector-preview__wrapper,
.c-editor-preview__wrapper {
	padding: 100px;
	height: 100%;
	width: 100%;
}

.c-selector-preview__video,
.c-editor-preview__video {
	object-fit: contain;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.c-editor-preview__music {
	display: none;
}

.c-editor-preview__buttons {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;

	button + button {
		margin-left: 20px;
	}
}

.c-editor-preview__title {
	background: #000;
	color: #fff;
	width: 100%;
	height: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 64px;
	font-weight: bold;
	line-height: normal;
}

// Intro

.c-nieuwsmaker-intro {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.c-nieuwsmaker-intro__video-wrapper {
	position: relative;
	width: 576px;
	height: 380px;
	// padding-bottom: 56.25%;
}

.c-nieuwsmaker-intro__video {
	object-fit: contain;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// Success

.c-nieuwsmaker-success {
	color: #ffffff;
	position: relative;
	max-width: 432px;
	width: 100%;
	margin: 0 auto;
	top: 50%;
	transform: translateY(-50%);
}

// Student app

.v-nieuwsmaker-mobile {
}

.c-nm-video-category {
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 2px solid #fff;
}

.c-nm-video {
	position: relative;

	+ .c-nm-video {
		margin-top: 20px;
	}
}

.c-nm-video__overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.c-nm-video__thumbnail {
	position: absolute;
	object-fit: cover;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.c-nm-video__video {
	width: 100%;
	height: auto;
}

.c-nm-video__play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.c-nm-video-collapse__videos {
	margin-top: 20px;
}
