// Global

:root {
  --color-bg: #000;
  --color-box-bg: #000;
  --color-maintext: #fff;
  --color-input-bg: rgba(0, 0, 0, 0.5);
  --color-warning: #d40d0d;
}


// App Header
$c-app-header-height: 58px;

$breakpoint-mobile: 768px;
