.c-team {
	@include reset-list();
	background-color: #000;
	border-radius: 8px;
	border: solid 1px #ffffff;
	padding: 0 20px;
	margin-top: 32px;
}

.c-team__member {
	font-size: 12px;
	line-height: 1.23;
	display: block;
	padding: 12px 0;

	+ .c-team__member {
		border-top: 1px solid #fff;
	}
}
