.c-simple-collapse {
}

.c-simple-collapse__content {
	height: 66px;
	overflow: hidden;
	margin-bottom: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

.c-simple-collapse--open {
	.c-simple-collapse__content {
		height: auto;
		display: block;
	}
}

.c-simple-collapse__button {
	background-color: transparent;
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	border: none;
	color: #fff;
	text-decoration: underline;
	font-weight: bold;
	font-size: 12px;
}
