.u-container {
	padding: 24px 20px;
}

.u-full-height {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// max-width: 360px;
	margin: auto;
	// padding-top: $c-app-header-height + 24px;

	@media (max-width: $breakpoint-mobile) {
		min-height: 100vh;
		min-height: var(--app-height);
		// max-width: none;
	}
}

.u-box {
	background-color: var(--color-box-bg);
	color: var(--color-maintext);
	border-radius: 8px;
	border: solid 1px #ffffff;
	padding: 20px;
}

.u-planet-color-1 {
	background-image: radial-gradient(circle at 50% 50%, #8e0e00, #211c18 99%, #1f1c18 100%);
}

.u-planet-color-2 {
	background-image: radial-gradient(circle at 50% 50%, #eea849, #f46b45 99%, #1f1c18 100%);
}

.u-planet-color-3 {
	background-image: radial-gradient(circle at 50% 50%, #0079c6, #363795 100%, #1f1c18 100%);
}

.u-planet-color-4 {
	background-image: radial-gradient(circle at 50% 50%, #1f1c18, #fc00ff, #69006a 100%);
}

.u-planet-color-5 {
	background-image: radial-gradient(circle at 50% 52%, #d7d2cc, #304352 95%);
}

.u-planet-color-6 {
	background-image: radial-gradient(circle at 50% 52%, #ba8b02, #181818 95%);
}

.u-planet-color-7 {
	background-image: radial-gradient(circle at 50% 52%, #194984, #2b363d 95%);
}

.u-slide {
	padding-top: $c-app-header-height;
	position: relative;
	min-height: 100vh;
}
