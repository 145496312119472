@mixin reset-list() {
	list-style: none;
	padding: 0;
	margin: 0;
}

@mixin reset-button() {
	margin: 0;
	padding: 0;
	overflow: visible;
	text-align: inherit;
	-webkit-appearance: none;
	cursor: pointer;
	border: none;
	background: transparent;
}
