.c-hamburger {
	background: transparent;
	margin: 0;
	padding: 0;
	border: none;
	width: 24px;
	height: 16px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
}

.c-hamburger span {
	display: block;
	height: 2px;
	width: 100%;
	background: #fff;
}
