:root.locale-nl_cmgames {
	// --color-bg: #b5dccf;
	--color-box-bg: #fff;
	--color-maintext: #000;
	--color-input-bg: white;
	--color-warning: #eb466b;

	body {
		font-family: 'Montserrat', 'sans-serif';
	}

	body:not(.app-presentation) {
		--color-bg: #b5dccf;
		.c-bg {
			&:after {
				background-image: none;
				filter: none;
			}
		}

		.u-station-color--orange-green .c-bg:after {
			background: #eb466b;
		}
		.u-station-color--purple-orange .c-bg:after {
			background: none;
		}
	}

	.t-title {
		text-transform: uppercase;
	}
	.c-leader-box__help {
		text-transform: uppercase;
		font-weight: 700;
	}

	.c-app-header {
		background-color: white;
	}
	.c-app-header__title {
		width: 100px;
	}

	.c-input--checkbox {
		background-color: white;

		label {
			&:before {
				border-color: #000;
			}
		}

		input:checked + label {
			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.765 2.13l-4.22 4.22a.8.8 0 01-1.132 0L.234 4.17A.8.8 0 011.366 3.04L2.98 4.65 6.633.997A.8.8 0 017.765 2.13z' fill='%23000' fill-rule='nonzero'/%3E%3C/svg%3E");
			}
		}
	}

	.v-auth {
		.c-button {
			background-image: none;
			background-color: #ef2660;
		}
	}

	.v-loading {
		&:after {
			background-image: none;
		}
	}

	.c-app-container .c-button--main {
		background-image: none;
		background-color: #fff698;
		color: var(--color-maintext);
	}

	.c-dice {
		background-color: #000;
	}

	.s-hmw__illustration,
	.s-what-if__illustration {
		background: none;
	}

	.c-slot__border {
		border-color: #1a6959;
		background-color: #b5dccf;
	}

	.c-timer {
		border-color: #b5dccf;
	}

	.c-steps {
		background-color: rgba(95, 164, 143, 0.5);
		font-weight: 500;
	}

	.c-radio-button input + label {
		background: #fff;
		color: var(--color-maintext);
		border: none;
	}
	.c-radio-button input:checked + label {
		background: #1a6959;
		color: #fff;
		font-weight: bold;
	}

	.v-nieuwsmaker {
		background: #000;

		.c-button {
			background-color: #1a6959;
			color: #fff;
		}

		.c-selector-monitor__duration,
		.c-recording-timer {
			font-family: 'Roboto Mono', monospace;
		}

		.c-nieuwsmaker-frame__main {
			background: #000;
		}

		.c-nieuwsmaker-frame__sidebar {
			background: #1a6959;
		}

		textarea {
			font-weight: bold;
		}
	}

	.c-login-teacher {
		color: var(--color-maintext);
	}
}
