.v-auth {
	position: relative;
	// min-height: 100vh;
	min-height: 100%;

	.t-title {
		margin: 0 0 8px;
	}

	.t-text {
		max-width: 316px;
		margin: 0 0 32px;
	}

	.u-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 360px;
		margin: auto;
		// padding-top: $c-app-header-height + 24px;

		@media (max-width: $breakpoint-mobile) {
			min-height: 100vh;
			min-height: calc(var(--app-height) - #{$c-app-header-height});
			max-width: none;
		}
	}

	.c-button {
		margin: 0 0 16px;
		background-image: linear-gradient(83deg, #721252, #fa9c26);
	}

	.c-section {
		margin-bottom: 32px;
	}
}
