.v-loading {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		display: block;
		top: -5%;
		left: -5%;
		right: -5%;
		bottom: -5%;
		filter: blur(22px);
		background-image: radial-gradient(circle at 52% 64%, #090406, #090406 29%, #4f008f 51%, #fa9c26 83%, #770101 120%, #770101 120%, #770101 120%);
		z-index: -1;
	}
}
