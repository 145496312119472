.c-question__media {
	margin-bottom: 40px;

	video,
	img {
		width: 100%;
		height: auto;
	}
}

.c-question__video {
	background-color: #000;
}

.c-question__footer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 16px;
	margin-top: 24px;

	[data-button-prev] {
		grid-column: 1 / 2;
	}

	[data-button-next] {
		grid-column: 2 / 3;
	}
}
