.c-alert {
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    border: solid 1px #ffffff;
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0 0 24px;
}

.c-alert--flash {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8);
    white-space: nowrap;

    button {
        color: #fff;
        background: transparent;
        border: 0;
    }
}