.c-button {
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 1.2px;
	padding: 12px;
	border-radius: 8px;
	text-transform: uppercase;
	border: 0;
	color: #fff;
	cursor: pointer;

	&:disabled {
		background-color: #ccc;
		background-image: none;
		opacity: 0.5;
	}
}

.c-button--full {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.c-button--transparent {
	background-color: transparent;
	border: 1px solid #fff;
}

// .c-button--orange {
// 	background-image: linear-gradient(83deg, #721252, #fa9c26);
// }

// .c-button--purple {
// 	background-image: linear-gradient(83deg, #721252, #4f008f);
// }

// .c-button--green {
// 	background-image: linear-gradient(83deg, #fa9c26, #1c6a59);
// }
