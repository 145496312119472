.connection-warning {
  position: absolute;
  top: 20px;
  text-align: center;
  font-size: 11px;
  padding: 20px;
  background: #333;
  font-weight: bold;
  display: none;
  width: 100%;
  z-index: 100;
  color: white;
  font-family: "Roboto Mono", monospace;
}

body.not-connected .connection-warning {
  display: block !important;
}
