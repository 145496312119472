.c-values {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 10px;
	row-gap: 10px;
	margin-bottom: 24px;

	&.c-values--max {
		.c-value__content {
			opacity: 0.5;
		}

		input:checked + .c-value__content {
			opacity: 1;
		}
	}
}

.c-value {
	width: 100%;
	padding-bottom: 100%;
	position: relative;

	input {
		display: none;
	}

	input:checked + .c-value__content {
		border-width: 4px;
	}
}

.c-value__content {
	text-align: center;
	position: absolute;
	border-radius: 10px;
	border: solid 1px #ffffff;
	background-color: #000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-flow: column;
	transition: opacity 0.3s;
}

.c-value__name {
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 1px;
	color: #ffffff;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 10px;
	hyphens: auto;
}

.c-value__model-wrapper {
	// padding: 20px;
	height: 80%;
	flex: 0 0 auto;
}

.c-value__model {
	// width: 200px;
	// height: 200px;
	width: 100%;
	height: 100%;
}
