.c-objects {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	row-gap: 10px;
	margin-top: 32px;
}

.c-object {
	background-color: #000;
	border-radius: 8px;
	border: solid 1px #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 150px;
}
