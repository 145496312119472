.c-input {
	margin-bottom: 24px;

	label {
		display: block;
		color: var(--color-maintext);
		font-size: 10px;
		font-weight: bold;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-bottom: 5px;

		a {
			color: inherit;
		}
	}

	input[type='text'],
	input[type='email'],
	input[type='password'],
	textarea {
		display: block;
		width: 100%;
		background-color: var(--color-input-bg);
		color: var(--color-maintext);
		border-radius: 8px;
		border: solid 1px #ffffff;
		padding: 12px;
		font-size: 16px; //Gedaan om iOS zoom te voorkomen
		line-height: 1.23;

		&:focus {
			outline: 0;
		}
	}
}

.c-input--password {
	position: relative;

	svg {
		position: absolute;
		width: 15px;
		height: 10px;
		top: 35px;
		right: 15px;
		cursor: pointer;
		fill: #fff;
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}
	}
}

.c-input-error {
	display: block;
	margin-top: 5px;
	color: var(--color-warning);
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.c-input--checkbox {
	border-radius: 8px;
	border: solid 1px #ffffff;
	padding: 12px;
	position: relative;

	label {
		position: relative;
		cursor: pointer;
		padding-left: 28px;
		margin: 0;
		font-size: 12px;
		line-height: 1.23;
		font-weight: normal;
		letter-spacing: normal;
		text-transform: initial;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			display: block;
			border: 1px solid #fff;
			width: 16px;
			height: 16px;
			border-radius: 4px;
		}
	}

	input {
		height: 40px;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 40px;
	}

	input:checked + label {
		&:before {
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.765 2.13l-4.22 4.22a.8.8 0 01-1.132 0L.234 4.17A.8.8 0 011.366 3.04L2.98 4.65 6.633.997A.8.8 0 017.765 2.13z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
		}
	}
}

.c-input--textarea {
	label {
		text-align: center;
		margin-bottom: 12px;
	}
}
