.s-questions {
	// min-height: 100vh;
	padding-top: $c-app-header-height;
	position: relative;
}

// .c-question__media {
// 	margin-bottom: 40px;

// 	video,
// 	img {
// 		width: 100%;
// 		height: auto;
// 	}
// }

// .c-question__footer {
// 	display: grid;
// 	grid-template-columns: 1fr 1fr;
// 	column-gap: 16px;
// 	margin-top: 24px;

// 	[data-button-prev] {
// 		grid-column: 1 / 2;
// 	}

// 	[data-button-next] {
// 		grid-column: 2 / 3;
// 	}
// }
