.c-planet {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.c-planet__3d {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.c-planet-list {
	@include reset-list();
	background-color: #000;
	border-radius: 8px;
	border: solid 1px #ffffff;
	padding: 0 20px;
}

.c-planet-item {
	padding: 20px;
	padding-left: 0;

	display: flex;
	flex-direction: row;

	.c-planet {
		width: 42px;
		height: 42px;
	}

	+ .c-planet-item {
		border-top: 1px solid #fff;
	}
}

.c-planet-item__shape {
	width: 42px;
	height: 42px;
	flex-grow: 0;
	position: relative;
}

.c-planet-item__info {
	margin-left: 20px;
	flex: 1;
	font-size: 12px;
	line-height: 1.23;
}

.c-planet-item__name {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 5px;
}

.c-planet-item__objects {
	//
}

.c-big-planet {
	width: 320px;
	height: 320px;
	display: flex;
	flex-direction: column;
	position: relative;
	.c-planet {
		flex-grow: 1;
	}
}

.c-big-planet__name {
	font-size: 20px;
	text-align: center;
	font-weight: bold;
	line-height: normal;
}

.c-big-planet__type {
	text-align: center;
	margin: 8px 0 32px 0;
}
