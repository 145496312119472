.c-planet-color-picker {
	margin: 30px 0;
}

.c-color-picker {
	padding: 8px;
	border-radius: 8px;
	border: solid 1px #ffffff;
	background-color: rgba(0, 0, 0, 0.8);
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	justify-items: center;
	grid-gap: 8px;
}

.c-color-picker-radio {
	position: relative;
	width: 100%;
	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	label {
		height: 100%;
		width: 100%;
		background-color: #000;
		border: 1px solid #fff;
		border-radius: 8px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
	}

	input {
		height: 100%;
		width: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	input:checked + label {
		border-width: 2px;
	}
}

.c-color-picker-radio__color {
	display: block;
	width: 100%;
	height: 100%;
}

// .c-planet-color-picker__planet {
// 	display: flex;
// 	justify-content: center;
// }
