.s-dice {
	padding-top: $c-app-header-height;
	//padding-top: 0;
	position: relative;
	// min-height: 100vh;
	/* mobile viewport bug fix */
	// min-height: -webkit-fill-available;

	height: var(--app-height);
}

.c-dice {
	width: 100%;
	//height: calc(100vh - #{$c-app-header-height});
	height: 100%;
	position: absolute;
	overflow: hidden;
	//top: $c-app-header-height;
	top: 0;
	left: 0;

	canvas {
		position: absolute;
		top: $c-app-header-height;
		left: 0;
		width: 100%;
		height: 100%;
	}

	padding: 24px 20px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	.c-button {
		z-index: 10;
		min-width: 250px;
		background-image: linear-gradient(83deg, #721252, #fa9c26);
		cursor: pointer;
	}
}
