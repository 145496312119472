.c-bg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		display: block;
		top: -5%;
		left: -5%;
		right: -5%;
		bottom: -5%;
	}
}

// Themes

// purple-orange
// station 1
// station 5
// dashboard (standaard)

.u-station-color--purple-orange {
	.c-bg {
		&:after {
			background-image: radial-gradient(circle at 60% 62%, #090406, #090406 39%, #4f008f 69%, #fa9c26 112%, #770101 161%, #770101 161%, #770101 161%);
		}
	}

	.c-button--main {
		background-image: linear-gradient(83deg, #721252, #fa9c26);
	}
}

.u-slide-color--purple-orange {
	.c-bg {
		&:after {
			filter: blur(22px);
			background-image: radial-gradient(circle at 52% 64%, #090406, #090406 29%, #4f008f 51%, #fa9c26 84%, #770101 120%, #770101 120%, #770101 120%);
		}
	}

	.c-button--main {
		background-image: linear-gradient(83deg, #721252, #fa9c26);
	}
}

// orange-green
// station 2
// station 4

.u-station-color--orange-green {
	.c-bg {
		&:after {
			filter: blur(36px);
			background-image: radial-gradient(circle at 50% 64%, #090406, #090406 32%, #1c6a59 57%, #fa9c26 93%, #770101 134%, #770101 134%, #770101 134%);
		}
	}

	.c-button--main {
		background-image: linear-gradient(83deg, #fa9c26, #1c6a59);
	}
}

.u-slide-color--orange-green {
	.c-bg {
		&:after {
			filter: blur(33px);
			background-image: radial-gradient(circle at 54% 65%, #090406, #090406 32%, #1c6a59 56%, #fa9c26 92%, #770101 132%, #770101 132%, #770101 132%);
		}
	}

	.c-button--main {
		background-image: linear-gradient(83deg, #fa9c26, #1c6a59);
	}
}

// purple-green
// station 3

.u-station-color--purple-green {
	.c-bg {
		&:after {
			filter: blur(43px);
			background-image: radial-gradient(circle at 48% 53%, #090406, #090406 34%, #3acbb0 59%, #4f008f 82%, #770101 139%, #770101 139%, #770101 139%);
		}
	}

	.c-button--main {
		// background-image: linear-gradient(83deg, #fa9c26, #1c6a59);
		background-image: linear-gradient(83deg, #721252, #4f008f);
	}
}

.u-slide-color--purple-green {
	.c-bg {
		&:after {
			filter: blur(33px);
			background-image: radial-gradient(circle at 58% 71%, #090406, #090406 26%, #3acbb0 46%, #4f008f 75%, #770101 108%, #770101 108%, #770101 108%);
		}
	}

	.c-button--main {
		// background-image: linear-gradient(83deg, #fa9c26, #1c6a59);
		background-image: linear-gradient(83deg, #721252, #4f008f);
	}
}

// red-orange
// station 6

.u-station-color--red-orange {
	.c-bg {
		&:after {
			filter: blur(43px);
			background-image: radial-gradient(circle at 48% 53%, #090406, #090406 31%, #770101 46%, #ffad3b 77%, #770101 139%);
		}
	}

	.c-button--main {
		background-image: linear-gradient(to right, #fa9c26, #770101);
	}
}

.u-slide-color--red-orange {
	.c-bg {
		&:after {
			filter: blur(43px);
			background-image: radial-gradient(circle at 48% 53%, #090406, #090406 33%, #770101 48%, #ffad3b 81%, #770101 145%);
		}
	}

	.c-button--main {
		background-image: linear-gradient(to right, #fa9c26, #770101);
	}
}

// purple

.u-station-color--purple {
	.c-bg {
		&:after {
			filter: blur(33px);
			background-image: radial-gradient(circle at 58% 71%, #090406, #090406 26%, #3acbb0 46%, #4f008f 75%, #770101 108%, #770101 108%, #770101 108%);
		}
	}

	.c-button--main {
		background-image: linear-gradient(83deg, #721252, #4f008f);
	}
}

.u-slide-color--purple {
	.c-bg {
		&:after {
			filter: blur(33px);
			background-image: radial-gradient(circle at 58% 71%, #090406, #090406 26%, #3acbb0 46%, #4f008f 75%, #770101 108%, #770101 108%, #770101 108%);
		}
	}

	.c-button--main {
		background-image: linear-gradient(83deg, #721252, #4f008f);
	}
}

// Oud

// .u-station-color--orange {
// 	.c-bg {
// 		&:after {
// 			filter: blur(36px);
// 			background-image: radial-gradient(circle at 50% 64%, #090406, #090406 32%, #770101 57%, #ffad3b 93%, #770101 134%, #770101 134%, #770101 134%);
// 		}
// 	}
// }

// .u-station-color--green {
// 	.c-bg {
// 		&:after {
// 			filter: blur(36px);
// 			background-image: radial-gradient(circle at 50% 64%, #090406, #090406 32%, #1c6a59 57%, #fa9c26 93%, #770101 134%, #770101 134%, #770101 134%);
// 		}
// 	}
// }

// .u-station-color--purple {
// 	.c-bg {
// 		&:after {
// 			filter: blur(36px);
// 			background-image: radial-gradient(circle at 50% 29%, #090406, #090406 27%, #4f008f 47%, #fa9c26 77%, #770101 111%, #770101 111%, #770101 111%);
// 		}
// 	}
// }

// .u-station-color--somecolor {
// 	.c-bg {
// 		&:after {
// 			filter: blur(36px);
// 			background-image: radial-gradient(circle at 50% 64%, #090406, #090406 32%, #1c6a59 57%, #fa9c26 93%, #770101 134%, #770101 134%, #770101 134%);
// 		}
// 	}
// }
