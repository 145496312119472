.c-leader-box {
	text-align: center;
	padding: 20px 45px;
}

.c-leader-box__text {
	margin: 0 0 32px 0;
	font-size: 24px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	color: var(--color-maintext);
}

.c-leader-box__help {
	font-size: 10px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: 1px;
}
