.c-steps {
	padding: 8px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 0.2);
	font-size: 10px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: 1px;
	color: var(--color-maintext);
	display: inline-block;
	margin-bottom: 8px;
	text-transform: uppercase;
}
