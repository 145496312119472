.c-radio-button {
	position: relative;

	label {
		width: 100%;
		background-color: #000;
		border: 1px solid #fff;
		border-radius: 8px;
		// height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: normal;
		line-height: 1.23;

		padding: 10px;
		text-align: center;
		min-height: 40px;
	}

	input {
		height: 100%;
		width: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	input:checked + label {
		background: #fff;
		color: #000;
	}

	+ .c-radio-button {
		margin-top: 12px;
	}
}
