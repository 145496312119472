.c-app-header {
	height: $c-app-header-height;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #edf2f6;
	padding: 0 20px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 50;
}

.c-app-header__title {
	width: 150px;
	height: auto;
}

.c-login-teacher {
	color: #fff;
	font-size: 11px;
}
